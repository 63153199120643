import React from "react"
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TourMenu from '../../components/TourMenu';
import { Helmet } from "react-helmet"
import {Link} from 'gatsby';

export default function ManageQuestions() {

    return (

        <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>Feature tour - get started | Skillmeter</title>
        </Helmet>
                
        <Header />

        <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl ">
              Feature tour
            </h2>
            <p className="mt-6 text-lg font-normal text-gray-600 ">
            See how easy is to get started with Skillmeter
          </p>               
          </div>
  
          <div className="max-w-5xl mx-auto mt-10 sm:mt-20">
            <div className="flow-root">
            <div className="-my-8 divide-y divide-gray-200">
                <div className="py-8">
                  <div className="flex">
                    <div className="w-1/4">
                        <TourMenu option="5" />

                    </div>
                    <div className="w-3/4">
                        <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl ">
                        Add your questions
                        </h2>               
                        <br /><br />       
                        Adding custom questions is as simple as <b>1-2-3</b>
                        <br /><br />
                        <b>First</b>, you have to select the question type. We currently offer 12 different types that you can add and mix in each of your tests:
                        <ul className="list-disc ml-4 mt-4 ">
                        <li>Instructions</li>
                        <li>Multiple choice with only one correct answer</li>
                        <li>Multiple choice with two or more correct answers</li>
                        <li>True/False</li>
                        <li>Ranking</li>
                        <li>Exact match</li>
                        <li>"Fill in the blank" / Essay</li>
                        <li>Picture Choice</li>
                        <li>File Upload</li>
                        <li>Typing Speed</li>
                        <li>Video Recording</li>
                        <li>Programming</li>

                        </ul>
<br /><br />

<img className="w-full max-w-md" src="https://d2mwjjtum67wgo.cloudfront.net/Content/Public3/img/tour/add-your-questions.png" alt="" />
<br /><br />

<b>Second</b>, you add the question description. With our state-of-art text editor, you can format the question text to meet your needs. You can also highlight blocks of text as Code. You can include images in your questions too.
<br /><br />

<b>Third</b> you add up to six possible answers, and check the box next to any correct answers.
<br /><br />

<img className="w-full max-w-md" src="https://d2mwjjtum67wgo.cloudfront.net/Content/Public3/img/tour/add-question.png" alt="" />

                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </section>
  
        <Footer />
      </>
    )
}