import React from 'react';
import {Link} from 'gatsby'

export default function TourMenu (props) {

    return (
        <>
        <Link to='/tour/' className={"px-2 py-1 rounded-md hover:bg-yellow-200 hover:cursor-pointer " + (props.option==1 ? 'bg-yellow-200' : '')}>1. Let's get started</Link>
        <br />
        <Link to='/tour/create-test'className={"px-2 py-1 rounded-md hover:bg-yellow-200 hover:cursor-pointer " + (props.option==2 ? 'bg-yellow-200' : '')}>2. Create a test</Link>
        <br />
        <Link to='/tour/programming-test' className={"px-2 py-1 rounded-md hover:bg-yellow-200 hover:cursor-pointer " + (props.option==3 ? 'bg-yellow-200' : '')}>3. Programming test</Link>
        <br />
        <Link to='/tour/questionnaire-test' className={"px-2 py-1 rounded-md hover:bg-yellow-200 hover:cursor-pointer " + (props.option==4 ? 'bg-yellow-200' : '')}>4. Questionnaire test</Link>
        <br />
        <Link to='/tour/manage-questions' className={"px-2 py-1 rounded-md hover:bg-yellow-200 hover:cursor-pointer " + (props.option==5 ? 'bg-yellow-200' : '')}>5. Add your questions</Link>
        <br />
        <Link to='/tour/manage-candidates' className={"px-2 py-1 rounded-md hover:bg-yellow-200 hover:cursor-pointer " + (props.option==6 ? 'bg-yellow-200' : '')}>6. Manage your candidates</Link>
        <br />
        <Link to='/tour/test-center' className={"px-2 py-1 rounded-md hover:bg-yellow-200 hover:cursor-pointer " + (props.option==7 ? 'bg-yellow-200' : '')}>7. Test center</Link>
        <br />
        <Link to='/tour/test-result' className={"px-2 py-1 rounded-md hover:bg-yellow-200 hover:cursor-pointer " + (props.option==8 ? 'bg-yellow-200' : '')}>8. Test result</Link>
        <br />
        <Link to='/tour/privacy-and-security' className={"px-2 py-1 rounded-md hover:bg-yellow-200 hover:cursor-pointer " + (props.option==9 ? 'bg-yellow-200' : '') }>9. Privacy and security</Link>   
        </>     
    );
}